import Head from "next/head";
import React, { PropsWithChildren } from "react";
import { LoginBanner } from "@castiero/web/components/login/LoginBanner";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";

const LoginLayout: React.FC<PropsWithChildren<{ page?: string }>> = ({
  children,
  page,
}) => {
  return (
    <>
      <Head>
        <title>{`Login ${page ? " | " + page : ""}`}</title>
      </Head>
      <Container
        maxWidth="xl"
        sx={{
          paddingInline: {
            xs: 0,
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100vh",
            gap: {
              sm: 2.5,
            },
          }}
        >
          <Grid xs={12} sm={5} md={6}>
            <LoginBanner />
          </Grid>
          <Grid xs={12} sm={6} md={5}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default LoginLayout;
