import { NextPage } from "next";
import localizationMiddleware from "@castiero/web/middleware/localizationMiddleware";
import authUserMiddleware from "@castiero/web/middleware/authUserMiddleware";
import { getAuthUserDashboardUrl } from "@castiero/web/common/getAuthUserDashboardUrl";
import { LoginForm } from "@castiero/web/components/login/LoginForm";
import LoginLayout from "@castiero/web/components/layouts/LoginLayout";

const Login: NextPage = () => {
  return (
    <LoginLayout page="login">
      <LoginForm />
    </LoginLayout>
  );
};

export const getServerSideProps = localizationMiddleware(
  authUserMiddleware(async (config, store) => {
    const redirect = getAuthUserDashboardUrl(config, store);
    if (redirect)
      return {
        redirect: {
          destination: redirect,
          permanent: false,
        },
      };

    return {
      props: {},
    };
  }, false)
);

export default Login;
