import { Button } from "@mui/material";
import { useTranslation } from "next-i18next";
interface SocialLoginProps {
  startIcon: React.ReactNode;
  children: string;
  provider: "facebook" | "google";
  action: "login" | "signup";
}

export const SocialLogin: React.FC<SocialLoginProps> = ({
  startIcon,
  children,
  provider,
  action,
}) => {
  const { i18n } = useTranslation();
  const handleLogin = async () => {
    location.href = `/api/oauth/${provider}?state=${action}:${i18n.language}`;
  };

  return (
    <Button
      variant="outlined"
      startIcon={startIcon}
      onClick={handleLogin}
      sx={{
        paddingBlock: { xs: ".4rem", lg: ".5rem" },
        color: "primary.light",
        borderRadius: "8.33rem",
        borderColor: "primary.light",
        fontSize: {
          xs: "1rem",
          md: "1.18rem",
        },
        textTransform: "none",
        MaxWidth: "19rem",
        width: {
          lg: "20rem",
        },
        fontWeight: 700,
      }}
    >
      {children}
    </Button>
  );
};
