import {
  Box,
  Button,
  FormControlLabel,
  Typography,
  Stack,
  Hidden,
} from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { SubmitHandler, useForm } from "react-hook-form";
import api from "@castiero/web/api";
import GoogleIcon from "@castiero/web/assets/svg/GoogleIcon";
import FacebookIcon from "@castiero/web/assets/svg/FacebookIcon";
import { SocialLogin } from "./components/SocialLogin";
import { CssTextField } from "./components/CustomField";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { ReCaptchaProvider, ReCaptcha } from "next-recaptcha-v3";
import CastieroLogin from "@castiero/web/assets/svg/CastieroLogin";
import { setCookie } from "cookies-next";
import { useState } from "react";

type LoginFormType = {
  email: string;
  password: string;
  remember_me: boolean;
  reCaptchaToken: string;
};

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const LoginForm = () => {
  const { t } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(6).max(32).required(),
    remember_me: Yup.boolean().default(false).nullable(),
  });

  const { register, handleSubmit, formState, setValue } =
    useForm<LoginFormType>({
      resolver: yupResolver(schema),
    });
  const router = useRouter();

  const onLogin: SubmitHandler<LoginFormType> = async (credentials) => {
    setIsSubmitting(true);
    api
      .post("login", credentials)
      .then(({ data: { redirects, api_key } }) => {
        setCookie("__cost_track_id", api_key);
        router.replace(redirects);
      })
      .catch(() => {
        setIsSubmitting(false);
        window.alert("las credenciales son inválidas");
      });
  };

  return (
    <ReCaptchaProvider reCaptchaKey={SITE_KEY} useEnterprise hidden>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        paddingY={1}
      >
        <Hidden smDown>
          <Box maxWidth="23.5rem">{<CastieroLogin width="100%" />}</Box>
        </Hidden>
        <Stack textAlign="center" sx={{ marginTop: 1 }} paddingX={1}>
          <Typography variant="h4" color="primary">
            {t("welcome_to")}
          </Typography>
          <Typography variant="h1" color="primary">
            {t("CASTIERO APP")}
          </Typography>
          <Typography color="primary.light" variant="subtitle1">
            {t("login_slogan")}
          </Typography>
        </Stack>

        <Box
          component="form"
          action="submit"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onSubmit={handleSubmit(onLogin)}
        >
          <ReCaptcha
            onValidate={(token) => setValue("reCaptchaToken", token)}
            action="submit"
          />
          <Stack
            spacing={0.4}
            sx={{
              maxWidth: 264,
              width: "100%",
            }}
          >
            <CssTextField
              disabled={isSubmitting}
              label={t("email")}
              type="email"
              {...register("email")}
              id="login.email"
              margin="normal"
              placeholder={t("enter_your_email")}
            />

            <CssTextField
              disabled={isSubmitting}
              label={t("password")}
              type="password"
              {...register("password")}
              id="login.password"
              margin="normal"
              placeholder={t("enter_your_password")}
            />
            <FormControlLabel
              disabled={isSubmitting}
              control={
                <Checkbox
                  {...register("remember_me")}
                  id="login.remember_me"
                  sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                      color: "#7E5700",
                    },
                  }}
                />
              }
              label={t("keep_me_signed_in")}
              sx={{ color: "#0000007f", fontSize: "3rem" }}
            />
            <Button
              variant="contained"
              disabled={!formState.isDirty || isSubmitting}
              type="submit"
              size="large"
              sx={{ fontSize: "1.16rem" }}
            >
              {t("login")}
            </Button>
            <Link href={`/password-resets`}>
              <Typography
                align="center"
                variant="h6"
                sx={{ color: "#0000007f", cursor: "pointer" }}
              >
                {t("forget_password")}
              </Typography>
            </Link>
          </Stack>
          <Stack
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Box
                sx={{
                  borderBottom: "1px solid #CAC4D0",
                  width: "6.5rem",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  opacity: 0.3,
                }}
              >
                {t("other")}
              </Typography>
              <Box
                sx={{
                  borderBottom: "1px solid #CAC4D0",
                  width: "6.5rem",
                }}
              ></Box>
            </Stack>
            <Stack
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                gap: {
                  xs: ".5rem",
                  xl: "1rem",
                },
                width: {
                  md: "100%",
                },
                justifyContent: "space-around",
              }}
            >
              <SocialLogin
                provider="google"
                action="login"
                startIcon={<GoogleIcon />}
              >
                {t("enter_with_google")}
              </SocialLogin>
              <SocialLogin
                provider="facebook"
                action="login"
                startIcon={<FacebookIcon />}
              >
                {t("enter_with_facebook")}
              </SocialLogin>
            </Stack>
            <Typography
              variant="body2"
              sx={{
                marginTop: {
                  lg: "1.5rem",
                },
              }}
            >
              {t("not_member_yet")}
              <a href=""> {t("get_started_now")}</a>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </ReCaptchaProvider>
  );
};
