import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Container } from "@mui/material";
import { Hidden } from "@mui/material";
import { Carousel } from "./components/Carousel";
import CardMedia from "@mui/material/CardMedia";
import { dataCarousel } from "./dataCarousel";
import { useState } from "react";
import { sizes } from "@castiero/web/common/theme";
import LogoCastiero from "@castiero/web/assets/svg/LogoCastiero";

export const LoginBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIndexChange = (newIndex: number) => {
    setCurrentIndex(newIndex);
  };

  return (
    <Container
      sx={{
        paddingTop: {
          xs: 1,
          sm: 0,
        },
        padding: {
          sm: 0,
        },
        maxHeight: 868,
        height: {
          sm: 838,
        },
      }}
    >
      <Hidden smUp>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingY={1}
        >
          <LogoCastiero
            style={{
              maxWidth: "50%",
              position: "relative",
              top: "-25%",
            }}
          />
        </Box>
      </Hidden>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="#FFDEAD"
        sx={{
          gap: "50px",
          width: "100%",
          height: { sm: "100%" },
          borderRadius: 4,
          padding: 2,
        }}
      >
        <Hidden smDown>
          <CardMedia
            title={dataCarousel[currentIndex].title}
            image={dataCarousel[currentIndex].img}
            sx={{
              width: "100%",
              maxWidth: {
                sm: 86,
                md: 142,
              },
              height: {
                sm: 86,
                md: 142,
              },
              borderRadius: "50%",
            }}
          />
        </Hidden>
        <Card
          sx={{
            maxWidth: 430,
            borderRadius: sizes.x4,
            backgroundColor: "#F8EFE7",
            paddingInline: 2,
            paddingTop: 2,
            marginTop: {
              sm: 3,
              md: 5,
            },
          }}
        >
          <Carousel onIndexChange={handleIndexChange} />
        </Card>
      </Box>
    </Container>
  );
};
