import { Box, Typography, Stack } from "@mui/material/";
import { DataCarousel } from "../dataCarousel";

export const CarouselItem = ({ item }: { item: DataCarousel }) => (
  <Stack spacing={1} textAlign="center">
    <Typography color="primary.light" variant="h2" marginTop={0.5}>
      {item.title}
    </Typography>
    <Typography variant="body1">{item.description}</Typography>
    <Box sx={{ opacity: 0.5 }}>
      <Typography variant="h2">{item.name}</Typography>
      <Typography variant="h2">{item.location}</Typography>
    </Box>
  </Stack>
);
