import { useState } from "react";

export const useCarousel = (onIndexChange: (n: number) => void) => {
  const [index, setIndex] = useState(0);
  const getColor = (i: number) => {
    return i === index ? "primary" : "default";
  };

  const handleIndexChange = (i: number) => {
    setIndex(i);
    onIndexChange(i);
  };

  return {
    index,
    getColor,
    handleIndexChange,
  };
};
