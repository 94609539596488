import { SVGProps } from "react";

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.983 12.492C24.983 5.592 19.392 0 12.492 0 5.592 0 0 5.593 0 12.492c0 6.235 4.568 11.403 10.54 12.34v-8.73H7.368v-3.61h3.172V9.74c0-3.131 1.865-4.86 4.718-4.86 1.367 0 2.797.244 2.797.244v3.074h-1.576c-1.551 0-2.035.963-2.035 1.952v2.342h3.464l-.554 3.61h-2.91v8.73c5.972-.937 10.54-6.105 10.54-12.34Z"
      fill="#1877F2"
    />
  </svg>
);

export default FacebookIcon;
