import { IconButton, Stack } from "@mui/material/";
import CircleIcon from "@mui/icons-material/Circle";
import { dataCarousel } from "../dataCarousel";
import { CarouselItem } from "./CarouselItem";
import { useCarousel } from "../hooks/useCarousel";

export const Carousel = ({
  onIndexChange,
}: {
  onIndexChange: (n: number) => void;
}) => {
  const { index, getColor, handleIndexChange } = useCarousel(onIndexChange);

  return (
    <>
      <CarouselItem item={dataCarousel[index]} />
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          margin: 1,
          marginTop: {
            md: "50px",
          },
        }}
      >
        {dataCarousel.map((item, i) => (
          <IconButton
            key={i}
            aria-label=""
            onClick={() => handleIndexChange(i)}
            color={getColor(i)}
          >
            <CircleIcon style={{ fontSize: "1rem" }} />
          </IconButton>
        ))}
      </Stack>
    </>
  );
};
