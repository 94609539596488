import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "../../../common/theme";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#1F1B16",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.light,
    },
  },
});
