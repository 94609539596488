export interface DataCarousel {
  img: string;
  title: string;
  description: string;
  name: string;
  location: string;
}

export const dataCarousel: DataCarousel[] = [
  {
    img: "https://randomuser.me/api/portraits/women/54.jpg",
    title: "Estudiante de cocina",
    description:
      "Antes de usar Castiero app, siempre me quedaba sin dinero a mitad de mes. Ahora puedo ver exactamente a dónde va mi dinero y hacer ajustes para asegurarme de que llegue hasta el final del mes.",
    name: "John Carter",
    location: "San Francisco, CA",
  },
  {
    img: "https://randomuser.me/api/portraits/women/51.jpg",
    title: "Empresario",
    description:
      "La interfaz de la app de Castiero es realmente atractiva y fácil de usar. Me encanta poder ver mis gastos y ahorros en un solo lugar, y recibir notificaciones útiles que me ayudan a mantenerme en el buen camino.",
    name: "Rodrigo Correa",
    location: "Bogotá, Colombia",
  },
  {
    img: "https://randomuser.me/api/portraits/women/60.jpg",
    title: "Diseñadora de Interiores",
    description:
      "Siempre he tenido problemas para mantener un presupuesto, pero esta app me ha ayudado a ser mucho más consciente de mis hábitos de gasto y a planificar mejor para el futuro. Ahora puedo ahorrar para mis objetivos a largo plazo, como comprar una casa, sin sentir que me estoy privando de las cosas que disfruto.",
    name: "Catalina Zuluaga",
    location: "Medellin, Colombia",
  },
];
